<template lang="pug">
.lots-list-options(v-if="options?.info?.unseenCountLots")
  .lots-list-options__card.row
    .block
      .label {{ foundTitle }}
      .content {{ options.info.count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}
    .block
      .label {{ sumTitle }}
      .content ~ {{ compactCostFormatter(options.info.currency || "RUB").format(options.info.sumCost) }}
  .lots-list-options__card.row
    .block
      .label.flex-row
        span Непросмотренных тендеров
        ui-warning(placement="bottom-start" size="small")
          .warning-content
            span.title Что такое непросмотренные тендеры?
            span <b>Непросмотренные тендеры</b> - это новые лоты, автоматически добавленные в шаблон после его сохранения. В списке результатов они выводятся выше, чем просмотренные лоты, и отмечаются оранжевым цветом фона и тегом "Новая закупка".
            ul
              li Отметить лот просмотренным можно одним из трех способов - при нахождении его названия в зоне видимости более 2 секунд (этот вариант выбран по умолчанию), при взаимодействии с карточкой лота и при клике на кнопку. Настроить этот параметр можно в
                |
                |
                router-link.link(:to="{ name: Tabs.Settings.InterfaceSettings }" target="_blank") Настройках функциональности.
              li Для того, чтобы непросмотренные лоты не копились, они автоматически отмечаются просмотренными спустя несколько дней. По умолчанию этот период равен 3 календарным дням. Настроить этот параметр можно в
                |
                |
                router-link.link(:to="{ name: Tabs.Settings.SystemManagement }" target="_blank") Системных настройках.
            span
              | Подробнее прочитать про непросмотренные лоты можно в
              |
              |
              router-link.link(:to="{ name: Tabs.Training.WatchedLots }" target="_blank") этой статье.
      .content {{ options.info.unseenCountLots?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}
    .block
      .label На сумму
      .content ~ {{ compactCostFormatter(options.info.currency || "RUB").format(options.info.unseenSumCost) }}

.lots-list-options(v-else)
  .lots-list-options__card(:class="!options?.info.selective && '_selective'")
    .label {{ foundTitle }}
    ui-loader.loader(v-if="options?.loading" mini)
    .content(v-else) {{ options.info.count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}
  .lots-list-options__card(v-if="options?.info.selective")
    .label {{ sumTitle }}
    ui-loader.loader(v-if="options?.loading" mini)
    .content(v-else) ~ {{ compactCostFormatter(options.info.currency || "RUB").format(options.info.sumCost) }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { compactCostFormatter } from "@/utils/formatter/costFormatter";

import type { PropType } from "vue";
import type { MainStreamListInfoOptions } from "@/utils/getters/defaultRequestInfo";
import UiLoader from "@/components/ui/loader/UiLoader.vue";
import UiWarning from "~/components/ui/tooltip/UiWarning.vue";
import { Tabs } from "@/router/tabs";

export default defineComponent({
  name: "ListOptions",
  components: {
    UiWarning,
    UiLoader,
  },
  methods: {
    compactCostFormatter,
  },
  props: {
    options: {
      type: Object as PropType<MainStreamListInfoOptions>,
      default: () => ({}),
    },
    foundTitle: {
      type: String,
      default: 'Найдено тендеров',
    },
    sumTitle: {
      type: String,
      default: 'На сумму',
    },
  },
  setup() {
    return {
      Tabs,
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/links';

.link {
  @include link-mixin;
}

.lots-list-options {
  display: flex;
  width: var(--main-content-width);
  justify-content: space-between;
  margin-bottom: 40px;

  .loader {
    width: 30px;
  }
}

.lots-list-options__card {
  display: flex;
  flex-flow: column;
  gap: 8px;
  text-align: center;
  justify-content: center;
  align-items: center;

  height: 74px;
  width: calc(50% - 10px);

  border: 1px solid #D4D7DE;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 40px;
  background-color: white;

  &.row {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 16px;
  }

  &._selective {
    width: 100%;
    flex-flow: row;
    gap: 40%;
  }

  .flex-row {
    display: flex;
    flex-flow: row;
    gap: 8px;
  }

  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  .content {
    font-size: 22px;
    font-weight: 700;
  }

  .block {
    display: flex;
    flex-flow: column;
    gap: 8px;
  }

  .warning-content {
    max-width: 420px;
    display: flex;
    flex-flow: column;
    gap: 4px;
    text-align: start;
    padding: 4px 2px;

    .title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }
}
</style>
